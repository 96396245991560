import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {DashboardLayoutA} from '../app/layouts'
import Formsy from 'formsy-react';
import {StripeCard, TextInput} from '../components/form';
import {LoginButton} from '../app/user/components'
import {Link} from 'gatsby'
import StripeInputManager from '../managers/stripe'
import {purchaseTeam} from '../state/account/actions'
import * as Paths from '../constants/Paths';

class PurchaseTeam extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      cardError: null,
      processing: false,
      success: false,
      showFormsyErrors: false
    }
  }

  onValidSubmit(form) {

    const { purchaseTeam } = this.props;

    this.setState({processing: true, error: null, cardError: null, showFormsyErrors: false});

    var addlseats = parseInt(form.seats);

    StripeInputManager()
      .createToken("teams")
      .then((token) => {
        purchaseTeam(form.name, addlseats || 0, token.id)
          .then(() => this.setState({processing: false, error: null, success: true, showFormsyErrors: false}))
          .catch((e) => this.setState({processing: false, error: e.message}));
      })
      .catch((e) => this.setState({cardError: e, processing: false, error: null, showFormsyErrors: false}));

  }

  onInvalidSubmit() {
    this.setState({ showFormsyErrors: true });
  }

  render() {

    const { user, loggedIn } = this.props;
    const { showFormsyErrors, cardError, error, success, processing } = this.state;

    return (
      <DashboardLayoutA>
        <div className="TeamsPage">
          <section className="grid-restrain grid-row v-align full-height">
            <div className="grid-item-2 grid-row v-align">
              <div className="premium-desc-block">
                <p>Collaborate together with all Team Cloud Features:</p>
                <ul>
                  <li>Shared Accounts and workspaces.</li>
                  <li>Component and environment inheritance between projects.</li>
                  <li>Auto-saving and syncing across all devices.</li>
                  <li>Simpler code generation via the CLI.</li>
                  <li>Persistance of results and other test data. (coming soon)</li>
                  <li>Cloud execution of tests. (coming soon)</li>
                </ul>
              </div>
            </div>
            {success ? (
              <div className="grid-item-3">
                <div className="card premium-card grid-row grid-column v-align h-align">
                  <div className="success-message">Your team has been created!</div>
                  <div className="success-message-2 s2">Your 7-day trial has begun.</div>
                  <div>Enter your new team via the account dropdown on the SnapTest extension.</div>
                  <a href="/doc/teams">Checkout our team docs to get started.</a>
                  <div>
                    <Link to={Paths.DB_USER_HOME} className="btn-primary">Manage Accounts</Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid-item-3">
                <div className="card premium-card">
                  <div className="grid-row h-align price-row">
                    <div className="price-period">Cloud Team Monthly</div>
                    <div className="price-amount">$360.00</div>
                    <div className="price-permonth"> / mo</div>
                  </div>
                  <div className="price-seats"><b>7-day trial period. </b>Includes 3 seats. Additional seats at $69.99</div>
                  {!loggedIn ? (
                    <div className="grid-item grid-row grid-column h-align v-align ">
                      <div className="for-account">
                        Account required to create a team. Please <LoginButton text="login"/> or <Link to={Paths.REGISTER}>create an account</Link>.
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="for-account">Account owner will be: {user.email}.</div>
                      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
                              onInvalidSubmit={() => this.onInvalidSubmit() }>
                        <div className="grid-row">
                          <div className="grid-item-3 sr2">
                            <TextInput name="name"
                                   type="text"
                                   icon="confirm"
                                   inputSize="pt-large"
                                   label="Seats:"
                                   placeholder="Team name"
                                   showErrors={showFormsyErrors}
                                   required />
                          </div>
                          <div className="grid-item">
                            <TextInput name="seats"
                                   type="number"
                                   inputSize="pt-large"
                                   label="Add'l seats:"
                                   placeholder="+ seats"
                                   showErrors={showFormsyErrors} />
                          </div>
                        </div>
                        <StripeCard name="cardToken"
                                    type="text"
                                    manager="teams"
                                    icon="credit-card"
                                    inputSize="pt-large"
                                    label="Credit Card Info"
                                    error={cardError}
                                    required />
                        <div className="confirmation-line"><b>Your card will not be charged</b> until the end of the 7-day trial period. Subscription can be managed or cancelled via the dashboard.</div>
                        {error && (
                          <div className="pt-callout pt-intent-danger s4">
                            {error}
                          </div>
                        )}
                        <button className={"pt-button pt-intent-primary3 pt-large sr2" + (processing ? " pt-disabled" : "")} disabled={processing}>
                          {processing ? "+ Creating..." : "+ Create Team Account"}
                        </button>
                      </Formsy>
                    </div>
                  )}
                </div>
              </div>
            )}
          </section>
        </div>
      </DashboardLayoutA>
    )
  }

}


export default connect(
  (state) => {
    return {
      user: state.account.user,
      loggedIn: state.account.loggedIn
    }
  },
  (dispatch) => (
    bindActionCreators({
      purchaseTeam
    }, dispatch)
  )
)(PurchaseTeam);
